import React from "react";
import Plot from 'react-plotly.js';

class Stockchart extends React.Component<{}, { xval:[], yval:[]}>{

    constructor(props) {
        super(props);
        this.state = {
            xval: [],
            yval: []
        }
    }



    componentDidMount() {
        this.daily_adjusted();

    }

    daily_adjusted() {

        const alpha = require('alphavantage')({ key: 'M2ASGY8QKXKK84DI' });
        alpha.data.daily_adjusted('IBM', 'compact', 'json').then(data =>{

            // all_days = Object.keys(Object.values(data)[1]);
            // let all_readings_obj = Object.values(data)[1];
            // let all_readings_arr = Object.values(all_readings_obj);
            // close_val = all_readings_arr.map(i => i["4. close"]);

            let times_obj = Object.entries(data["Time Series (Daily)"]) as any;
            let dates_val = times_obj.map(i => i[0]);

            let close_val = times_obj.map(i => i[1]["4. close"]);
            //console.log(dates_val);

            this.setState({
                xval: dates_val,
                yval: close_val
            });

        });

    }

    render() {
        return (
            <div>
                <h2>Stock close values for IBM (Isaac)</h2>
                <Plot
                    data={[
                        {
                            x: this.state.xval,
                            y: this.state.yval,
                            type: 'scatter',
                            mode: 'lines+markers',
                            marker: {color: 'red'},
                        }
                    ]} layout={{width: 720, height:440, title:'IBM'}} />

            </div>
        )
    }
    
}

export default Stockchart;
