import React from "react";
import Stockchart from "./stockchart";

const App = () => {

  //const data = daily_adjusted(); //[{ x: all_days, y: close_val}];
  //console.log(data);
  return (
      // <Paper>
      //   <Chart
      //       data={data}
      //   >
      //     <ArgumentAxis />
      //     <ValueAxis />
      //
      //     <LineSeries valueField="y" argumentField="x" />
      //   </Chart>
      // </Paper>

      <Stockchart />
  );
}

export default App;
